.form-control {
  display: block;
  width: 100%;
  height: 3.75658rem;
  padding: 1.375rem 0.75rem 1.25rem;
  font-family: "Soehne", sans-serif;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.13158;
  color: #2b3435;
  background-color: #f6f6f8;
  background-clip: padding-box;
  border: 0;
  border-radius: 0.125rem;
  transition: background-color 0.15s ease-in-out;
}
.frame-background-light .form-control {
  background-color: rgba(43, 52, 53, 0.08);
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
@media (min-width: 992px) {
  .form-control {
    padding: 1.5rem 1rem 1.375rem;
    height: 4.00658rem;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #2b3435;
}
.form-control:focus {
  color: #2b3435;
  background-color: #ececee;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #dadadc;
  opacity: 1;
}
.form-control:disabled {
  cursor: not-allowed;
}
.form-control[type=file] {
  padding-top: 1.25rem;
}
@media (min-width: 992px) {
  .form-control[type=file] {
    padding-top: 1.375rem;
  }
}

textarea.form-control {
  height: auto;
  min-height: 8rem;
  resize: vertical;
}

.form-group {
  margin-bottom: 1rem;
}

.form-set {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}

/*.valid-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    @include font-size($form-feedback-font-size);
    color: $color;
}

@include form-validation-state-selector($state) {
    ~ .valid-feedback,
    ~ .valid-tooltip {
        display: block;
    }
}*/
.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%2339d130' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z'%3e%3c/path%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}
@media (min-width: 992px) {
  .was-validated .form-control:valid, .form-control.is-valid {
    padding-right: 2rem;
    background-position: right 1rem center;
  }
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 1.5rem;
  background-position: top 1.375rem right 0.75rem;
}
@media (min-width: 992px) {
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: 2rem;
    background-position: top 1.5rem right 1rem;
  }
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%2339d130' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z'%3e%3c/path%3e%3c/svg%3e") #f6f6f8 no-repeat center right 1.75rem/calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem);
}

.was-validated .custom-control-input:valid, .custom-control-input.is-valid {
  /*            &:checked {
                  ~ .custom-control-label::before {
                      border-color: lighten($color, 10%);
                      @include gradient-bg(lighten($color, 10%));
                  }
              }

              &:focus {
                  ~ .custom-control-label::before {
                      box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                  }

                  &:not(:checked) ~ .custom-control-label::before {
                      border-color: $color;
                  }
              }*/
}

/*.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    @include font-size($form-feedback-font-size);
    color: $color;
}

@include form-validation-state-selector($state) {
    ~ .invalid-feedback,
    ~ .invalid-tooltip {
        display: block;
    }
}*/
.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%23c22f56' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z'%3e%3c/path%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}
@media (min-width: 992px) {
  .was-validated .form-control:invalid, .form-control.is-invalid {
    padding-right: 2rem;
    background-position: right 1rem center;
  }
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 1.5rem;
  background-position: top 1.375rem right 0.75rem;
}
@media (min-width: 992px) {
  .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: 2rem;
    background-position: top 1.5rem right 1rem;
  }
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%23c22f56' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z'%3e%3c/path%3e%3c/svg%3e") #f6f6f8 no-repeat center right 1.75rem/calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem);
}

.was-validated .custom-control-input:invalid, .custom-control-input.is-invalid {
  /*            &:checked {
                  ~ .custom-control-label::before {
                      border-color: lighten($color, 10%);
                      @include gradient-bg(lighten($color, 10%));
                  }
              }

              &:focus {
                  ~ .custom-control-label::before {
                      box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                  }

                  &:not(:checked) ~ .custom-control-label::before {
                      border-color: $color;
                  }
              }*/
}
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #c22f56;
}

label {
  font-size: 0.95rem;
  line-height: 1.13158;
}

.form-group-floating {
  position: relative;
}
.form-group-floating label {
  position: absolute;
  left: 0.75rem;
  top: 1.375rem;
  color: #6c757d;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 992px) {
  .form-group-floating label {
    left: 1rem;
    top: 1.5rem;
  }
}
.form-group-floating input:focus + label,
.form-group-floating textarea:focus + label, .form-group-floating.input-filled > label {
  font-size: 0.625rem;
  top: 0.25rem;
  color: #2b3435;
}
@media (min-width: 992px) {
  .form-group-floating input:focus + label,
  .form-group-floating textarea:focus + label, .form-group-floating.input-filled > label {
    top: 0.5rem;
  }
}

.custom-control {
  position: relative;
  z-index: 1;
}
.custom-control .custom-control-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  z-index: -1;
}
.custom-control .custom-control-label {
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 2.5rem;
}
.custom-control .custom-control-label::before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  margin-top: -0.75rem;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: rgba(43, 52, 53, 0.08);
}
.frame-background-dark .custom-control .custom-control-label::before {
  background-color: #f6f6f8;
}
.custom-control .custom-control-label::after {
  position: absolute;
  display: none;
  content: "";
  top: 50%;
  margin-top: -6px;
}
.custom-control:hover .custom-control-label::before {
  background-color: rgba(43, 52, 53, 0.12);
}
.frame-background-dark .custom-control:hover .custom-control-label::before {
  background-color: #ececee;
}
.custom-control .custom-control-input:focus ~ .custom-control-label::before {
  background-color: rgba(43, 52, 53, 0.12);
}
.frame-background-dark .custom-control .custom-control-input:focus ~ .custom-control-label::before {
  background-color: #ececee;
}
.custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #58e650;
}
.frame-background-dark .custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #58e650;
}
.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  display: block;
}
.custom-control.custom-control-checkbox .custom-control-label::before {
  border-radius: 0.125rem;
}
.custom-control.custom-control-checkbox .custom-control-label::after {
  width: 6px;
  height: 12px;
  left: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-control.custom-control-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-control.custom-control-radio .custom-control-label::after {
  width: 12px;
  height: 12px;
  left: 6px;
  background-color: #fff;
  border-radius: 50%;
}

label.form-group-label {
  font-weight: 700;
  font-size: 0.95rem;
  margin-bottom: 1rem;
}

.custom-control a {
  text-decoration: underline;
}
.custom-control.font-xs .custom-control-label {
  font-size: 0.8125rem;
  line-height: 1.57895;
}
.custom-control.custom-control-block {
  width: 100%;
  padding-left: 0;
  margin-bottom: 1rem;
}
.custom-control.custom-control-block .custom-control-label {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.75658rem;
  height: 3.75658rem;
  background-color: #f6f6f8;
  padding: 0.75rem 0.75rem 0.75rem 3.25rem;
  border-radius: 0.125rem;
}
@media (min-width: 992px) {
  .custom-control.custom-control-block .custom-control-label {
    min-height: 4.00658rem;
    height: 4.00658rem;
  }
}
.custom-control.custom-control-block .custom-control-label:hover {
  background-color: #ececee;
}
.custom-control.custom-control-block .custom-control-label::before {
  left: 0.75rem;
}
.custom-control.custom-control-block.custom-control-checkbox .custom-control-label::after {
  left: 1.3125rem;
}
.custom-control.custom-control-block.custom-control-radio .custom-control-label::after {
  left: 1.125rem;
}
.custom-control.custom-control-block .custom-control-input:checked ~ .custom-control-label {
  background-color: #39d130;
  color: #fff;
}
.custom-control.custom-control-block .custom-control-input:focus:not(:checked) ~ .custom-control-label {
  background-color: #ececee;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3.75658rem;
  padding: 1.375rem 0.75rem 1.25rem;
  font-family: "Soehne", sans-serif;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.13158;
  color: #2b3435;
  vertical-align: middle;
  background: #f6f6f8 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 0;
  border-radius: 0.125rem;
  appearance: none;
}
@media (min-width: 992px) {
  .custom-select {
    padding: 1.5rem 1rem 1.375rem;
    height: 4.00658rem;
  }
}
.custom-select:focus {
  color: #2b3435;
  background-color: #ececee;
  outline: 0;
  box-shadow: none;
}
.custom-select:focus::-ms-value {
  color: #2b3435;
  background-color: #f6f6f8;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #2b3435;
}

.form-group-select {
  position: relative;
}
.form-group-select label {
  position: absolute;
  left: 0.75rem;
  top: 0.25rem;
  color: #2b3435;
  font-size: 0.625rem;
}
@media (min-width: 992px) {
  .form-group-select label {
    left: 1rem;
    top: 0.5rem;
  }
}

.help-block {
  font-size: 0.8125rem;
  line-height: 1.57895;
  display: inline-block;
}